import manu from "../../img/members/IMG_4988-min.JPG";
import member1 from "../../img/members/IMG_4985-min.JPG";
import member2 from "../../img/members/IMG_4984-min.JPG";
import React, {useState} from "react";
import Slider from "react-slick";

export default function OurBarbers() {

    const [sliderRef, setSliderRef] = useState(null);

    const barbers = [{
        name: "Emanuel",
        description: "Dedicated barber for 10+ years, specializing in creating a unique experience. I blend your life story with my passion for barbering, ensuring a comfortable and personalized style. Let's craft the perfect look for you.",
        type: "Barber",
        img: manu
    }, {
        name: "Pablo",
        description: "I love getting to know my clients desires which helps me achieve a haircut which exceeds their expectations.",
        type: "Barber",
        img: member1
    }, {
        name: "Oliver",
        description: "I always drew inspiration from urban lifestyles, underground cultures, street art, and music. And it’s always a joy to be part of clients’ most important life moments with a haircut that becomes part of their comfort",
        type: "Barber",
        img: member2
    }]

    var settings = {
        arrows: false,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const ComponentBarber = () => {
        return barbers.map((barber, i) => {
            return <div className="w-9/12 md:w-full mx-auto">
                <div className="relative group bg-[#2a2a2a]">
                    <img
                        className={`object-cover w-full max-h-350 lg:w-full lg:max-h-[700px]`}
                        src={barber.img}
                        alt=""
                    />
                    <div
                        className={`absolute top-0 flex justify-end h-full w-full bg-black bg-opacity-60 invisible group-hover:visible`}
                    >
                        <div className="flex justify-center items-center">
                            <p className="text-white text-center font-medium text-sm md:text-sm xl:text-md px-2">{barber.description}</p>
                        </div>
                    </div>
                    <div
                        className={`bg-darker w-full h-full flex flex-col items-center mx-auto justify-center font-teko py-3`}
                    >
                        <h1 className={`text-xl xl:text-2xl text-white`}>{barber.name}</h1>
                        <h2 className={`text-sm  xl:text-base text-primary`}>
                            - BARBER
                        </h2>
                    </div>
                </div>
                <a className="flex justify-center mx-auto mt-4" href="https://phorest.com/book/salons/jaegerbarbershop"
                   target="_blank">
                    <button
                        id="highlightButton"
                        className={`flex flex-row justify-center items-center px-0.5 py-1.5 xl:py-2 lg:mx-10  rounded-full text-white font-medium text-sm md:text-base xl:text-lg w-24 s:w-32 md:w-40 xl:w-44 font-teko bg-[#B6816F] opacity-90 shadow-[#B6816F] shadow-lg`}
                    >
                        <h1 className="text-center mt-1">BOOK APPOINTMENT</h1>
                    </button>
                </a>
            </div>
        })
    }

    return <div className="w-full">
        <div
            className="hidden md:flex w-10/12 xl:w-8/12 2xl:w-5/12 mx-auto grid grid-cols-1 md:grid-cols-3 gap-2 lg:grid-cols-">
            <ComponentBarber/>
        </div>

        <div className={`flex md:hidden relative w-11/12 sm:w-11/12 mx-auto`}>
            <button
                onClick={sliderRef?.slickPrev}
                className={`bg-darker z-10 absolute top-1/2 left-0 md:left-[40px] lg:left-[110xspx] xl:left-[125px] bg-white rounded-full p-1.5`}
            >
                <div className={``}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-white"
                        fill="#fff"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M10 19l-7-7m0 0l7-7m-7 7h18"
                        />
                    </svg>
                </div>
            </button>
            <Slider
                ref={setSliderRef}
                {...settings}
                className={`reviews w-10/12 xs:w-11/12 sm:w-9/12 mx-auto`}
            >
                {barbers.map((barber, i) => {
                    return <div className="flex justify-center mx-auto py-2.5">
                        <div className="relative group">
                            <img
                                className={`object-cover w-full max-h-[450px] lg:w-full lg:max-h-[700px]`}
                                src={barber.img}
                                alt=""
                            />
                            <div
                                className={`absolute top-0 flex justify-end h-full w-full bg-black bg-opacity-60 invisible group-hover:visible`}
                            >
                                <div className="flex justify-center items-center">
                                    <p className="leading-[1.5rem] text-white text-center font-medium text-sm md:text-xs xl:text-sm px-2">{barber.description}</p>
                                </div>
                            </div>
                            <div
                                className={`bg-darker w-full h-full flex flex-col items-center mx-auto justify-center font-teko py-3`}
                            >
                                <h1 className={`text-xl xl:text-2xl text-white`}>{barber.name}</h1>
                                <h2 className={`text-sm  xl:text-base text-primary`}>
                                    - BARBER
                                </h2>
                            </div>
                        </div>
                        <a className="flex justify-center mx-auto mt-2.5 p-4 w-full"
                           href="https://phorest.com/book/salons/jaegerbarbershop" target="_blank">
                            <button
                                id="highlightButton"
                                className={`flex flex-row justify-center items-center px-1 py-1.5 xl:py-2 lg:mx-10  rounded-full text-white font-medium text-sm md:text-base xl:text-lg w-24 s:w-32 md:w-40 xl:w-44 font-teko bg-[#B6816F] opacity-90 shadow-[#B6816F] shadow-lg`}
                            >
                                <h1 className="text-center mt-1">BOOK APPOINTMENT</h1>
                            </button>
                        </a>
                    </div>
                })}
            </Slider>
            <button
                onClick={sliderRef?.slickNext}
                className={`bg-darker absolute top-1/2 right-0 md:right-[40px] lg:right-[110xspx] xl:right-[125px] bg-white rounded-full p-1.5`}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                    />
                </svg>
            </button>
        </div>

    </div>
}