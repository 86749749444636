import Navbar from "./Navbar";
import Footer from "./Footer";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import logo from "../img/logo.svg";
import React from "react";
// import { ShareButton } from "react-custom-share";

export default function PressSingle() {
  // const shareButtonProps = {
  //   url: window.location.href,
  //   network: "Facebook",
  // };
  const { id } = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    getData();
  }, [id]);

  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/user/blog/${id}`, {})
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {});
  };

  return (
    <div>
      <Navbar />

      {/*header*/}
      <div
        className={`relative -z-20 bg-black flex flex-col mx-auto items-center py-12 s:py-16 sm:py-20 xl:py-24 space-y-3 xl:space-y-5`}
      >
        <img
          className={`-z-10 opacity-20 absolute w-24 w-[150px] lg:w-[200px] xl:w-[300px] top-1/2 -translate-y-1/2`}
          src={logo}
          alt=""
        />

        <h1
          className={`z-0 uppercase text-white font-teko text-center text-5xl xl:text-6xl`}
        >
          press single
        </h1>
      </div>

      {/*press section*/}
      <div className={`bg-[#f4f2eb] relative`}>
        <div
          className={`2xl:container mx-auto py-12 lg:py-20 xl:py-24  flex flex-col sm:flex-row justify-center px-4 xs:px-6 sm:px-8 md:px-12 xl:px-16 sm:gap-8 md:gap-6   xl:gap-20 `}
        >

          {/*article*/}
          <div
            className={` flex flex-col space-y-6 md:space-y-0 2xl:space-y-6 items-center `}
          >
            <div className={`w-full relative`}>
              <div
                className={`bg-cover bg-center h-48 xs:h-56 s:h-60 sm:h-72 md:h-80 lg:h-96 xl:h-[500px] `}
                style={{ backgroundImage: `url(${data?.image})` }}
              ></div>
              <div className={` py-6 lg:py-8`}>
                <h1
                  className={`font-teko font-medium text-darker text-2xl lg:text-4xl`}
                >
                  {data?.title}
                </h1>

                <p
                  dangerouslySetInnerHTML={{ __html: data?.description }}
                  className={` mt-3 lg:mt-6 text-xs lg:text-sm text-[#585858] font-semibold`}
                ></p>
              </div>

              <div
                className={`flex flex-row justify-end space-y-3 md:space-y-0`}
              >
                <div className={`flex flex-row `}>
                  <h1 className={`font-teko text-xl`}>SHARE:</h1>
                  {/*<ShareButton {...shareButtonProps}  className={`mt-1 ml-1 h-4 w-4`}>*/}
                  {/*<svg*/}
                  {/*  xmlns="http://www.w3.org/2000/svg"*/}
                  {/*  viewBox="0 0 48 48"*/}

                  {/*>*/}
                  {/*  <path*/}
                  {/*    fill="#039be5"*/}
                  {/*    d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z"*/}
                  {/*  />*/}
                  {/*  <path*/}
                  {/*    fill="#fff"*/}
                  {/*    d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z"*/}
                  {/*  />*/}
                  {/*</svg>*/}
                  {/*</ShareButton>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
