import Navbar from "./Navbar";
import Footer from "./Footer";
import blackM from "../img/blackM.png";
import chair from "../img/chair.png";
import { useState } from "react";
import axios from "axios";
import { useMemo } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

export default function Contact() {

  const containerStyle = {
    width: "100%",
    height: "400px",
  };

  const center = {
    lat: 48.19347,
    lng: 16.34856,
  };

  const [success, setSuccess] = useState();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const { name, email, message } = formData;

  const sendMessage = (e) => {
    e.preventDefault();
    if (!isValidEmail(email)) {
      setSuccess(2);
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}/contact`, {
          name,
          email,
          message,
        })
        .then((response) => {
          setFormData({ ...formData, name: "", email: "", message: "" });
          setSuccess(1);
        })
        .catch((err) => {
          setSuccess(0);
        });
    }
  };

  const handleChange = (text) => (e) => {
    setFormData({ ...formData, [text]: e.target.value });
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  return (
    <div>
      <Navbar />

      <div className="pt-20">
        {/*header*/}

        <LoadScript googleMapsApiKey="AIzaSyAuNRsGuc4dt7psZYBzXSJM1xk6cMTZC60">
          <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={13}>
            <Marker position={center} />
            <></>
          </GoogleMap>
        </LoadScript>

        {/*contact form*/}
        <div className={`relative`}>
          <div className={`2xl:container mx-auto  py-12 lg:py-20 xl:py-24`}>
            <div
                className={`pb-10 sm:pb-16 xl:pb-20 flex flex-col sm:flex-row sm:space-x-6 md:space-x-12 lg:space-x-0 px-6 justify-center lg:justify-between items-center sm:items-start lg:w-2/3 mx-auto space-y-8 sm:space-y-0 `}
            >
              <div
                  className={`w-44  xl:w-48 text-[10px] xl:text-xs text-white text-center  flex flex-col items-center `}
              >
                <div
                    className={` w-10 xl:w-10 h-10 xl:h-10 relative bg-primary flex flex-row justify-center items-center text-white`}
                >
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 xl:h-5 xl:w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                  >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                    />
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                </div>
                <h1 className={`text-xs font-bold uppercase text-dark mt-4`}>
                  Location
                </h1>
                <h2
                    className={`text-xs font-semibold text-lightgray uppercase leading-loose mt-2`}
                >
                  Hirschengasse 5,1060 Wien
                </h2>
              </div>
              <div
                  className={`w-44  xl:w-48 text-[10px] xl:text-xs text-white text-center  flex flex-col items-center `}
              >
                <div
                    className={` w-10 xl:w-10 h-10 xl:h-10 relative bg-primary flex flex-row justify-center items-center text-white`}
                >
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      enableBackground="new 0 0 53.942 53.942"
                      version="1.1"
                      viewBox="0 0 53.942 53.942"
                      xmlSpace="preserve"
                      className="h-4 w-4 xl:h-5 xl:w-5"
                      stroke="#fff"
                      fill="none"
                      strokeWidth="4"
                  >
                    <path d="M53.364 40.908c-2.008-3.796-8.981-7.912-9.288-8.092-.896-.51-1.831-.78-2.706-.78-1.301 0-2.366.596-3.011 1.68-1.02 1.22-2.285 2.646-2.592 2.867-2.376 1.612-4.236 1.429-6.294-.629L17.987 24.467c-2.045-2.045-2.233-3.928-.632-6.291.224-.309 1.65-1.575 2.87-2.596.778-.463 1.312-1.151 1.546-1.995.311-1.123.082-2.444-.652-3.731-.173-.296-4.291-7.27-8.085-9.277a4.947 4.947 0 00-2.306-.573 4.908 4.908 0 00-3.49 1.445L4.7 3.986C.686 7.999-.767 12.548.379 17.506c.956 4.132 3.742 8.529 8.282 13.068l14.705 14.705c5.746 5.746 11.224 8.66 16.282 8.66h.001c3.72 0 7.188-1.581 10.305-4.698l2.537-2.537a4.908 4.908 0 00.873-5.796z" />
                  </svg>
                </div>

                <h1 className={`text-xs font-bold uppercase text-dark mt-4`}>
                  call us
                </h1>
                <a href="tel:+4318908780">
                  <h2
                      className={`text-xs font-semibold text-lightgray uppercase leading-loose mt-2`}
                  >
                    +4318908780
                  </h2>
                </a>
              </div>
              <div
                  className={`w-44  xl:w-48 text-[10px] xl:text-xs text-white text-center  flex flex-col items-center `}
              >
                <div
                    className={` w-10 xl:w-10 h-10 xl:h-10 relative bg-primary flex flex-row justify-center items-center text-white`}
                >
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                  >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                    />
                  </svg>
                </div>
                <h1 className={`text-xs font-bold uppercase text-dark mt-4`}>
                  email us
                </h1>
                <a href="mailto:kontakt@jaegerbarbershop.com">
                  <h2
                      className={`text-xs font-semibold text-lightgray uppercase leading-loose mt-2`}
                  >
                    kontakt@jaegerbarbershop.com
                  </h2>
                </a>
              </div>
            </div>
            <div
                className={`p-4 xs:px-6 sm:px-20 flex flex-col items-center text-center mx-auto space-y-3 xl:space-y-5`}
            >
              <h1
                  className={`font-teko text-5xl xl:text-6xl font-medium text-darker`}
              >
                CONTACT US
              </h1>
              <img className={`w-40 xl:w-48`} src={blackM} alt="" />
            </div>
            {success === 1 ? (
                <h1
                    className={`text-center mx-auto text-green-600 font-medium pt-4 text-lg`}
                >
                  Your message has been successfully sent.{" "}
                </h1>
            ) : success === 0 ? (
                <h1
                    className={`text-center mx-auto text-red-600 font-medium pt-4 text-lg`}
                >
                  Message not delivered.
                </h1>
            ) : success === 2 ? (
                <h1
                    className={`text-center mx-auto text-red-600 font-medium pt-4 text-lg`}
                >
                  Email is invalid.
                </h1>
            ) : null}
            <form
                onSubmit={sendMessage}
                className={`w-full mt-4 sm:mt-10 xl:mt-14 flex flex-col items-center relative`}
            >
              <div
                  className={`w-4/5 sm:w-3/5  flex flex-col items-center mx-auto space-y-4 xl:space-y-6`}
              >
                <div
                    className={`flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-8 xl:space-x-10 w-full`}
                >
                  <div className={`w-full  border border-darker`}>
                    <input
                        type="text"
                        className={`w-full focus:outline-none bg-transparent py-2.5 xl:py-3 pl-4 text-[10px] xl:text-xs placeholder:text-[#787675] text-[#787675]`}
                        placeholder={`YOUR NAME*`}
                        value={name}
                        onChange={handleChange("name")}
                        required
                    />
                  </div>
                  <div className={`w-full  border border-darker`}>
                    <input
                        type="text"
                        className={`w-full focus:outline-none bg-transparent py-2.5 xl:py-3 pl-4 text-[10px] xl:text-xs placeholder:text-[#787675] text-[#787675]`}
                        placeholder={`EMAIL ADRESS*`}
                        value={email}
                        onChange={handleChange("email")}
                    />
                  </div>
                </div>
                <div className={`w-full h-24 border border-darker`}>
                <textarea
                    className={`w-full h-full focus:outline-none bg-transparent py-2.5 xl:py-3 pl-4 text-[10px] xl:text-xs placeholder:text-[#787675] text-[#787675]`}
                    placeholder={`YOUR MESSAGE...`}
                    value={message}
                    onChange={handleChange("message")}
                    required
                />
                </div>
              </div>
              <button
                  type="submit"
                  className={`mt-6 xl:mt-8 mx-auto bg-primary text-white font-bold text-xs xl:text-sm px-5 xl:px-6 py-3 xl:py-3.5 `}
              >
                SEND A MESSAGE
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
