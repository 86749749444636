import logo from "../img/logo.svg";

export default function Footer() {
    return (
        <div className={`bg-dark`}>
            <div className={`2xl:container mx-auto font-semibold`}>
                <div
                    className={`py-12 xl:py-16 flex flex-row flex-wrap gap-12 md:gap-24  justify-center items-start px-8 xl:px-12 `}
                >
                    <div
                        className={`w-44  xl:w-48 text-[10px] xl:text-xs text-white text-center uppercase flex flex-col items-center`}
                    >
                        <div
                            className={`border-t border-primary w-10 xl:w-12 h-12 xl:h-14 pt-3 xl:pt-3.5 relative bg-[#434343] flex flex-row justify-center text-white`}
                        >
                            <div
                                className={`absolute -bottom-5 xl:-bottom-7 bg-dark w-7 h-7 xl:w-9 xl:h-9 -left-3 rotate-[30deg]`}
                            ></div>
                            <div
                                className={`absolute -bottom-5 xl:-bottom-7 bg-dark w-7 h-7 xl:w-9 xl:h-9 -right-3 rotate-[60deg]`}
                            ></div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-3 w-3 xl:h-4 xl:w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                />
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                            </svg>
                        </div>
                        <h1 className={`mt-8  text-lightgray`}>
                            Hirschengasse 5,1060 Wien
                        </h1>
                    </div>
                    <div
                        className={`w-44  xl:w-48 text-[10px] xl:text-xs text-white text-center uppercase flex flex-col items-center`}
                    >
                        <div
                            className={`border-t border-primary w-10 xl:w-12 h-12 xl:h-14 pt-3 xl:pt-3.5 relative bg-[#434343] flex flex-row justify-center text-white`}
                        >
                            <div
                                className={`absolute -bottom-5 xl:-bottom-7 bg-dark w-7 h-7 xl:w-9 xl:h-9 -left-3 rotate-[30deg]`}
                            ></div>
                            <div
                                className={`absolute -bottom-5 xl:-bottom-7 bg-dark w-7 h-7 xl:w-9 xl:h-9 -right-3 rotate-[60deg]`}
                            ></div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-3 w-3 xl:h-4 xl:w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                />
                            </svg>
                        </div>

                        <h1 className={`mt-8  text-lightgray`}>
                            Book appointment
                            <br/>
                            <a className="hover:text-white duration-200" href="tel:+4318908780">
                                +4318908780
                            </a>
                        </h1>

                    </div>

                    <div
                        className={`w-44 my-auto xl:w-48 text-[10px] xl:text-xs text-lightgray text-center uppercase flex flex-col items-center`}>
                        <span className="text-[13px]">Jäger Barber Manufaktur GmbH</span>
                        <p className="mt-1">UID-Nr.: ATU78238729</p>
                        <p>Firmebuch: 579408x
                            Hirschengasse 5 1060 Wien</p>
                    </div>

                </div>
                <div
                    className={` border-t border-[#434343] w-full flex flex-row  justify-between sm:grid grid-cols-3 items-center py-4 sm:py-1 xl:py-2 px-4 sm:px-8 xl:px-12`}
                >
                    <div className={`uppercase text-[10px] xl:text-xs text-lightgray`}>
                        <h1>2024 All Rights Reserved</h1>
                    </div>
                    <div className={`hidden sm:block w-20 xl:w-24 justify-self-center`}>
                        <img src={logo}/>
                    </div>
                    <div className={` justify-self-end flex flex-row space-x-1`}>
                        <div
                            className={`border-b border-primary w-9 h-9 xl:w-10 xl:h-10 bg-[#434343] flex flex-row items-center justify-center`}
                        >
                            {" "}
                            <a className="text-[#4267B2]">
                                <svg
                                    fill="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    className="w-3 h-3 xl:w-4 xl:h-4"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"/>
                                </svg>
                            </a>
                        </div>
                        <a href="https://instagram.com/jaegerbarbershop?igshid=YmMyMTA2M2Y=" className="text-[#C13584]">
                            <button
                                className={`border-b border-primary w-9 h-9 xl:w-10 xl:h-10  bg-[#434343] flex flex-row items-center justify-center`}
                            >
                                {" "}

                                <svg
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    className="w-3 h-3 xl:w-4 xl:h-4"
                                    viewBox="0 0 24 24"
                                >
                                    <rect width="20" height="20" x="2" y="2" rx="5" ry="5"/>
                                    <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"/>
                                </svg>
                            </button>
                        </a>
                        <a className="">
                            <button
                                className={`border-b border-primary w-9 h-9 xl:w-10 xl:h-10 bg-[#434343] flex flex-row items-center justify-center`}
                            >
                                {" "}

                                <svg fill="#E60023" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" className="w-3 h-3 xl:w-4 xl:h-4">
                                    <path
                                        d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609825 4 46 13.390175 46 25 C 46 36.609825 36.609825 46 25 46 C 22.876355 46 20.82771 45.682142 18.896484 45.097656 C 19.75673 43.659418 20.867347 41.60359 21.308594 39.90625 C 21.570728 38.899887 22.648438 34.794922 22.648438 34.794922 C 23.348841 36.132057 25.395277 37.263672 27.574219 37.263672 C 34.058123 37.263672 38.732422 31.300682 38.732422 23.890625 C 38.732422 16.78653 32.935409 11.472656 25.476562 11.472656 C 16.196831 11.472656 11.271484 17.700825 11.271484 24.482422 C 11.271484 27.636307 12.94892 31.562193 15.634766 32.8125 C 16.041611 33.001865 16.260073 32.919834 16.353516 32.525391 C 16.425459 32.226044 16.788267 30.766792 16.951172 30.087891 C 17.003269 29.871239 16.978043 29.68405 16.802734 29.470703 C 15.913793 28.392399 15.201172 26.4118 15.201172 24.564453 C 15.201172 19.822048 18.791452 15.232422 24.908203 15.232422 C 30.18976 15.232422 33.888672 18.832872 33.888672 23.980469 C 33.888672 29.796219 30.95207 33.826172 27.130859 33.826172 C 25.020554 33.826172 23.440361 32.080359 23.947266 29.939453 C 24.555054 27.38426 25.728516 24.626944 25.728516 22.78125 C 25.728516 21.130713 24.842754 19.753906 23.007812 19.753906 C 20.850369 19.753906 19.117188 21.984457 19.117188 24.974609 C 19.117187 26.877359 19.761719 28.166016 19.761719 28.166016 C 19.761719 28.166016 17.630543 37.176514 17.240234 38.853516 C 16.849091 40.52931 16.953851 42.786365 17.115234 44.466797 C 9.421139 41.352465 4 33.819328 4 25 C 4 13.390175 13.390175 4 25 4 z"/>
                                </svg>
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
