
import React from 'react';
import ReactDOM from 'react-dom/client';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './index.css';
import {BrowserRouter as Router,
    Routes,
    Route} from "react-router-dom";
import AdminRoute from "./components/routes/AdminRoute"
import Home from "./components/Home";
import About from "./components/About";
import Services from "./components/Services";
import Appointment from "./components/Appointment";
import Contact from "./components/Contact";
import Press from "./components/Press";
import PressSingle from "./components/PressSingle";
import Gallery from "./components/Gallery";
import Login from "./components/Login";
import AD_Dashboard from "./components/admin/AD_Dashboard";
import AD_Press from "./components/admin/AD_Press";
import AD_Gallery from "./components/admin/AD_Gallery";
import AD_BlogDetails from "./components/admin/AD_BlogDetails";
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
            <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/appointment" element={<Appointment />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/press" element={<Press />} />
            <Route path="/blog/:id" element={<PressSingle />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/login" element={<Login />} />

                <Route path="/admin/dashboard" element={< AdminRoute/>} >
                    <Route path="/admin/dashboard" element={<AD_Dashboard/>} />
                </Route>
                <Route path="/admin/press" element={< AdminRoute/>} >
                    <Route path="/admin/press" element={<AD_Press/>} />
                </Route>
                <Route path="/admin/blog/:id" element={< AdminRoute/>} >
                    <Route path="/admin/blog/:id" element={<AD_BlogDetails/>} />
                </Route>
                <Route path="/admin/gallery" element={< AdminRoute/>} >
                    <Route path="/admin/gallery" element={<AD_Gallery/>} />
                </Route>
            </Routes>
    </Router>,
);

reportWebVitals();
