import Navbar from "./Navbar";
import Footer from "./Footer.js";

// IMAGES
import logo from "../img/logo.svg";
import manu from "../img/members/IMG_4988.JPG";
import whiteM from "../img/whiteM.png";
import blackM from "../img/blackM.png";
import icon1 from "../img/icon1.png";
import icon2 from "../img/icon2.png";
import icon3 from "../img/icon3.png";
import slider1 from "../img/sliders/IMG_5327-min.JPG"
import slider2 from "../img/sliders/IMG_5326-min.JPG"
import slider3 from "../img/sliders/IMG_5001-min.JPG"
import slider4 from "../img/sliders/IMG_5299-min.JPG"
import slider5 from "../img/sliders/IMG_5328-min.JPG"
import slider6 from "../img/sliders/LogoBg.jpg"
import React from "react";
import Slider from "react-slick";
import Reviews from "./Reviews";
import {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {Dialog, Transition} from "@headlessui/react";
import {Fragment, useRef} from "react";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import OurBarbers from "./helpers/OurBarbers";

export default function Home() {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [gallery, setGallery] = useState([]);
    const [lateModal, setLateModal] = useState(false);
    const [cancelModal, setCancelModal] = useState(false);
    const cancelButtonRef = useRef(null);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/`, {})
            .then((res) => {
                setData(res.data);
                setBlogs(res.data.blogs);
                setGallery(res.data.gallery);
            })
            .catch((err) => {
            });
    };

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 375,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 1536,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 3000,
                settings: {
                    slidesToShow: 7,
                    slidesToScroll: 3,
                },
            },
        ],
    };
    return (
        <div>
            <Navbar/>
            {/*header*/}
            {/*<div*/}
            {/*    className={`relative -z-20 bg-black flex flex-col mx-auto items-center py-60 xs:py-60 lg:py-64 xl:py-72 space-y-3 xl:space-y-5`}*/}
            {/*>*/}
            {/*    <img*/}
            {/*        className={`-z-10 absolute w-[350px] lg:w-[400px] xl:w-[500px] top-1/2 -translate-y-1/2`}*/}
            {/*        src={logo}*/}
            {/*        alt=""*/}
            {/*    />*/}
            {/*</div>*/}

            {/*<div className={`-z-20 flex flex-col mx-auto items-center py-60 xs:py-60 lg:py-64 xl:py-72 space-y-3 xl:space-y-5`}>*/}
                <div className="pt-[5rem] relative">
                    <Swiper
                        loop={true}
                        spaceBetween={30}
                        centeredSlides={true}
                        pagination={{
                            clickable: true,
                            dynamicBullets: true,
                        }}
                        navigation={true}
                        autoplay={{
                            delay: 3500,
                            disableOnInteraction: false
                        }}
                        modules={[Pagination, Autoplay, Navigation]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className="w-full"
                            >
                                <img className="w-full max-h-[33rem] xl:max-h-[37rem] 2xl:max-h-[60rem] overflow-hidden object-cover bg-cover bg-no-repeat" src={slider1} />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="w-full bg-center bg-cover"
                            >
                                <img className="w-full max-h-[33rem] xl:max-h-[37rem] 2xl:max-h-[60rem] overflow-hidden object-cover bg-cover bg-no-repeat" src={slider2} />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="w-full bg-center bg-cover"
                            >
                                <img className="w-full max-h-[33rem] xl:max-h-[37rem] 2xl:max-h-[60rem] overflow-hidden object-cover bg-cover bg-no-repeat" src={slider3} />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="w-full bg-center bg-cover"
                            >
                                <img className="w-full max-h-[33rem] xl:max-h-[37rem] 2xl:max-h-[60rem] overflow-hidden object-cover bg-cover bg-no-repeat" src={slider4} />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="w-full bg-center bg-cover"
                            >
                                <img className="w-full max-h-[33rem] xl:max-h-[37rem] 2xl:max-h-[60rem] overflow-hidden object-cover bg-cover bg-no-repeat" src={slider5} />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="w-full bg-center bg-cover"
                            >
                                <img className="w-full max-h-[33rem] xl:max-h-[37rem] 2xl:max-h-[60rem] overflow-hidden object-cover bg-cover bg-no-repeat" src={slider6} />
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            {/*</div>*/}

            {/*section 1*/}
            <div
                className={`pt-6 px-4 xs:px-6 sm:px-20 lg:pt-8 xl:pt-12 `}
            >
                <div
                    className={`2xl:container flex flex-col items-center text-center text-darker mx-auto py-4 lg:py-8 xl:py-12 space-y-3 xl:space-y-5 relative`}
                >
                    {/*<h1 className={`font-teko text-4xl xl:text-5xl font-medium`}>*/}
                    {/*    OUR PHILOSOPHY*/}
                    {/*</h1>*/}
                    {/*<img className={`w-40 xl:w-48`} src={blackM} alt=""/>*/}
                    <p
                        className={`md:w-2/3 lg:w-1/2 text-xs xl:text-sm leading-[1.5rem] lg:leading-[2rem] xl:leading-[2.5rem]`}
                    >
                        Jäger Barbershop exudes a hipster modern vibe, where sleek industrial design seamlessly blends with rustic elements.Our skilled barbers provide tailored grooming experiences, ensuring every visit is a fusion of style and relaxation
                    </p>
                </div>
            </div>

            {/*section 3*/}
            <div className={``}>
                <div className={`py-12 lg:py-20 xl:py-24 p-4 xs:px-6 sm:px-20 md:px-0`}>
                    <div
                        className={`flex flex-col items-center text-center mx-auto space-y-3`}
                    >
                        <h1
                            className={`font-teko text-4xl xl:text-5xl font-medium text-darker`}
                        >
                            OUR BARBER
                        </h1>
                        <img className={`w-40 xl:w-48`} src={blackM} alt=""/>
                    </div>

                    <div
                        className={`flex flex-col flex-wrap md:flex-nowrap s:flex-row mx-auto justify-center items-center space-y-6 s:space-y-0 s:gap-4 sm:gap-8 md:gap-0 md:space-x-4 xl:space-x-6 mt-6 xs:mt-8 lg:mt-10 xl:mt-16`}
                    >
                        <OurBarbers/>
                    </div>
                </div>
            </div>


            {/*section 5*/}
            <div className={`bg-dark`}>
                <div
                    className={`2xl:container mx-auto py-12 lg:py-16 xl:py-20 flex flex-col items-center uppercase`}
                >
                    <h1
                        className={`text-white font-teko text-4xl xl:text-5xl font-medium `}
                    >
                        working hours
                    </h1>

                    {" "}
                    <h2
                        className={`text-white font-teko text-3xl lg:text-4xl xl:text-5xl mt-4`}
                    >
                        <span className={`text-primary`}>call us:</span> <a href="tel:+4318908780"> +4318908780 </a>
                    </h2>

                    <div
                        className={`grid grid-cols-2 s:grid-cols-3 gap-3 s:gap-4 md:gap-6 lg:gap-0 lg:flex lg:flex-row lg:space-x-4 xl:space-x-6 mt-8 lg:mt-10 xl:mt-14`}
                    >
                        <div
                            className={`text-center rounded-lg border-2 border-primary text-white px-2.5 xl:px-3.5 py-3 xl:py-4 bg-[#2a2a2a]`}
                        >
                            <h1 className={`text-3xl xl:text-4xl font-teko`}>TUES</h1>
                            <h2 className={`text-[11px] xl:text-xs font-bold`}>
                                10.00 AM - 7.00 PM
                            </h2>
                        </div>
                        <div
                            className={`text-center rounded-lg border-2 border-primary text-white px-2.5 xl:px-3.5 py-3 xl:py-4 bg-[#2a2a2a]`}
                        >
                            <h1 className={`text-3xl xl:text-4xl font-teko`}>WED</h1>
                            <h2 className={`text-[11px] xl:text-xs font-bold`}>
                                10.00 AM - 7.00 PM
                            </h2>
                        </div>
                        <div
                            className={`text-center rounded-lg border-2 border-primary text-white px-2.5 xl:px-3.5 py-3 xl:py-4 bg-[#2a2a2a]`}
                        >
                            <h1 className={`text-3xl xl:text-4xl font-teko`}>THUR</h1>
                            <h2 className={`text-[11px] xl:text-xs font-bold`}>
                                10.00 AM - 7.00 PM
                            </h2>
                        </div>
                        <div
                            className={`text-center rounded-lg border-2 border-primary text-white px-2.5 xl:px-3.5 py-3 xl:py-4 bg-[#2a2a2a]`}
                        >
                            <h1 className={`text-3xl xl:text-4xl font-teko`}>FRI</h1>
                            <h2 className={`text-[11px] xl:text-xs font-bold`}>
                                9.00 AM - 7.00 PM
                            </h2>
                        </div>
                        <div
                            className={`text-center rounded-lg border-2 border-primary text-white px-2.5 xl:px-3.5 py-3 xl:py-4 bg-[#2a2a2a]`}
                        >
                            <h1 className={`text-3xl xl:text-4xl font-teko`}>SAT</h1>
                            <h2 className={`text-[11px] xl:text-xs font-bold`}>
                                9.00 AM - 5.00 PM
                            </h2>
                        </div>
                    </div>
                    <h3
                        className={`text-center text-white font-teko text-3xl xl:text-4xl mt-6 lg:mt-12 xl:mt-16`}
                    >
                        sunday & monday: <span className={`text-primary`}>closed</span>
                    </h3>
                    <h3
                        className={`text-center text-white font-teko text-3xl xl:text-4xl mt-2 lg:mt-4 xl:mt-6`}
                    >
                        <span className={`text-primary`}>closed for lunch: </span>14:00 -
                        15:00
                    </h3>
                </div>
            </div>

            {/*section 6*/}
            <div
                className={``}
            >
                <div
                    className={`py-12 lg:py-20 xl:py-24
        `}
                >
                    <div
                        className={`px-4 xs:px-6 sm:px-20  flex flex-col items-center text-center mx-auto space-y-3 xl:space-y-5`}
                    >
                        <h1
                            className={`font-teko text-4xl xl:text-5xl font-medium text-darker`}
                        >
                            LATEST PRESS
                        </h1>
                        <img className={`w-40 xl:w-48`} src={blackM} alt=""/>
                    </div>

                    <div
                        className={`flex flex-row flex-wrap gap-3 items-center justify-center mt-8 xl:mt-12`}
                    >
                        {blogs.map((data, i) => (
                            <div key={i} className={`w-60 xl:w-72 relative mx-2 xs:mx-2 lg:mx-6`}>
                                <div
                                    className={`absolute bg-primary text-center font-teko text-white w-14 xl:w-16 py-1 xl:py-2 top-[100px] left-4 xl:left-6`}
                                >
                                    <h1 className={`text-2xl xl:text-3xl`}>25</h1>
                                    <h1 className={`text-base xl:text-lg -mt-2`}>AUGUST</h1>
                                </div>
                                <div className={`h-36 xl:h-40 bg-cover bg-center`}
                                     style={{backgroundImage: `url(${data.image})`}}>
                                </div>
                                <div className={`h-40 xl:h-44 hover:bg-gray-100 duration-200 border-2 p-5 xl:p-7`}>
                                    <h1 className={`font-teko text-darker text-lg xl:text-xl`}>
                                        {data.title}
                                    </h1>
                                    <div className={`hide`}>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: blogs[i].description,
                                            }}
                                            className={`hide-p text-[11px] xl:text-xs with-inline-block overflow-hidden text-darker`}
                                        /></div>
                                    <button
                                        onClick={() => {
                                            navigate(`/blog/${data._id}`);
                                        }}
                                        className={`mt-3 xl:mt-5 flex flex-row items-center font-bold text-[11px] xl:text-xs text-primary`}
                                    >
                                        <h1>READ MORE</h1>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="ml-1  h-3 w-3"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M13 5l7 7-7 7M5 5l7 7-7 7"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="flex flex-col bg-dark py-8">
                <Reviews/>
                <a href="https://g.page/r/CYX5P9YHZ2XXEB0/review" target="_blank" className="bg-white flex justify-center mx-auto items-center rounded-xl mt-6 p-1.5">
                    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="30px" height="30px"><path fill="#fbc02d" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12	s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"/><path fill="#e53935" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039	l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"/><path fill="#4caf50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"/><path fill="#1565c0" d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"/></svg>
                    <span className="font-bold ml-1.5 text-sm">Add review</span>
                </a>
            </div>

            {/*section 8*/}
            <div className={`relative`}>
                <div className={`2xl:container mx-auto  py-12 lg:py-20 xl:py-24`}>
                    <div
                        className={`px-4 xs:px-6 sm:px-20  flex flex-col items-center text-center mx-auto space-y-3 xl:space-y-5`}
                    >
                        <h1
                            className={`font-teko text-4xl xl:text-5xl font-medium text-darker`}
                        >
                            HOUSE RULES
                        </h1>
                        <img className={`w-40 xl:w-48`} src={blackM} alt=""/>
                    </div>

                    <div
                        className={`flex flex-row flex-wrap px-6 justify-center  items-center gap-12 md:gap-24 lg:gap-56 mt-12 lg:mt-20`}
                    >
                        <div
                            className={`w-44  xl:w-48 text-[10px] xl:text-xs text-white text-center  flex flex-col items-center `}
                        >
                            <div
                                className={` w-10 xl:w-10 h-10 xl:h-10 relative bg-primary flex flex-row justify-center items-center text-white`}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </div>
                            <h1 className={`text-xs font-bold uppercase text-dark mt-4`}>
                                late policy
                            </h1>
                            <button
                                onClick={() => {
                                    setLateModal(true);
                                }}
                                className={`text-xs font-semibold text-lightgray uppercase leading-loose mt-2`}
                            >
                                learn more
                            </button>
                        </div>
                        <div
                            className={`w-44  xl:w-48 text-[10px] xl:text-xs text-white text-center  flex flex-col items-center `}
                        >
                            <div
                                className={` w-10 xl:w-10 h-10 xl:h-10 relative bg-primary flex flex-row justify-center items-center text-white`}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    enableBackground="new 0 0 53.942 53.942"
                                    version="1.1"
                                    viewBox="0 0 53.942 53.942"
                                    xmlSpace="preserve"
                                    className="h-4 w-4 xl:h-5 xl:w-5"
                                    stroke="#fff"
                                    fill="none"
                                    strokeWidth="4"
                                >
                                    <path
                                        d="M53.364 40.908c-2.008-3.796-8.981-7.912-9.288-8.092-.896-.51-1.831-.78-2.706-.78-1.301 0-2.366.596-3.011 1.68-1.02 1.22-2.285 2.646-2.592 2.867-2.376 1.612-4.236 1.429-6.294-.629L17.987 24.467c-2.045-2.045-2.233-3.928-.632-6.291.224-.309 1.65-1.575 2.87-2.596.778-.463 1.312-1.151 1.546-1.995.311-1.123.082-2.444-.652-3.731-.173-.296-4.291-7.27-8.085-9.277a4.947 4.947 0 00-2.306-.573 4.908 4.908 0 00-3.49 1.445L4.7 3.986C.686 7.999-.767 12.548.379 17.506c.956 4.132 3.742 8.529 8.282 13.068l14.705 14.705c5.746 5.746 11.224 8.66 16.282 8.66h.001c3.72 0 7.188-1.581 10.305-4.698l2.537-2.537a4.908 4.908 0 00.873-5.796z"/>
                                </svg>
                            </div>
                            <h1 className={`text-xs font-bold uppercase text-dark mt-4`}>
                                cancellation policy
                            </h1>
                            <button
                                onClick={() => {
                                    setCancelModal(true);
                                }}
                                className={`text-xs font-semibold text-lightgray uppercase leading-loose mt-2`}
                            >
                                learn more
                            </button>
                        </div>

                        <Transition.Root show={lateModal} as={Fragment}>
                            <Dialog
                                as="div"
                                className="fixed z-30 inset-0 overflow-y-auto"
                                initialFocus={cancelButtonRef}
                                onClose={(e) => {
                                    setLateModal(e);
                                }}
                            >
                                <div
                                    className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-150"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-150"
                                        leaveFrom="opacity-70"
                                        leaveTo="opacity-0"
                                    >
                                        <Dialog.Overlay
                                            className="fixed inset-0 backdrop-filter backdrop-blur-sm bg-gray-300 bg-opacity-75 transition-opacity"/>
                                    </Transition.Child>

                                    {/* This element is to trick the browser into centering the modal contents. */}
                                    <span
                                        className="hidden sm:inline-block align-middle sm:h-screen"
                                        aria-hidden="true"
                                    >
                    &#8203;
                  </span>
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-150"
                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                        leave="ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    >
                                        <div
                                            className="inline-block align-top bg-white rounded-2xl text-left overflow-hidden shadow-lg transform transition-all sm:my-8 w-11/12 sm:align-middle sm:max-w-xl md:max-w-2xl">
                                            <div
                                                className="relative w-full flex-col relative w-full p-6 sm:p-8 md:p-12 text-center">
                                                <button
                                                    onClick={() => {
                                                        setLateModal(false);
                                                    }}
                                                    className={`absolute top-4 right-4`}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke-width="1.5"
                                                        stroke="currentColor"
                                                        class="w-6 h-6"
                                                    >
                                                        <path
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            d="M6 18L18 6M6 6l12 12"
                                                        />
                                                    </svg>
                                                </button>
                                                <div>
                                                    <h1
                                                        className={`font-teko text-4xl xs:text-5xl xl:text-6xl font-medium text-darker`}
                                                    >
                                                        LATE POLICY
                                                    </h1>
                                                    <p
                                                        className={` text-xs xl:text-sm leading-[1.5rem] lg:leading-[2rem] xl:leading-[2.5rem]`}
                                                    >
                                                        Being on time its meaning you are already late.
                                                        Because time is precious for all of us, we ask you
                                                        to be there between 5 and 10 minutes earlier so you
                                                        can enjoy your service and we have the necessary
                                                        time for everyone.Please notes if you are to late
                                                        and don’t let us know we reserve the right to cancel
                                                        your appointment, or try to feel you in our schedule
                                                        later if possible for that day! Thank you for your
                                                        understanding.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Transition.Child>
                                </div>
                            </Dialog>
                        </Transition.Root>

                        <Transition.Root show={cancelModal} as={Fragment}>
                            <Dialog
                                as="div"
                                className="fixed z-30 inset-0 overflow-y-auto"
                                initialFocus={cancelButtonRef}
                                onClose={(e) => {
                                    setCancelModal(e);
                                }}
                            >
                                <div
                                    className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-150"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-150"
                                        leaveFrom="opacity-70"
                                        leaveTo="opacity-0"
                                    >
                                        <Dialog.Overlay
                                            className="fixed inset-0 backdrop-filter backdrop-blur-sm bg-gray-300 bg-opacity-75 transition-opacity"/>
                                    </Transition.Child>

                                    {/* This element is to trick the browser into centering the modal contents. */}
                                    <span
                                        className="hidden sm:inline-block align-middle sm:h-screen"
                                        aria-hidden="true"
                                    >
                    &#8203;
                  </span>
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-150"
                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                        leave="ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    >
                                        <div
                                            className="inline-block align-top bg-white rounded-2xl text-left overflow-hidden shadow-lg transform transition-all sm:my-8 w-11/12 sm:align-middle sm:max-w-xl md:max-w-2xl">
                                            <div
                                                className="relative w-full flex-col relative w-full p-8 md:p-12 text-center">
                                                <button
                                                    onClick={() => {
                                                        setCancelModal(false);
                                                    }}
                                                    className={`absolute top-4 right-4`}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke-width="1.5"
                                                        stroke="currentColor"
                                                        class="w-6 h-6"
                                                    >
                                                        <path
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            d="M6 18L18 6M6 6l12 12"
                                                        />
                                                    </svg>
                                                </button>
                                                <div>
                                                    <h1
                                                        className={`font-teko text-4xl xs:text-5xl xl:text-6xl font-medium text-darker`}
                                                    >
                                                        CANCELLATION POLICY
                                                    </h1>
                                                    <p
                                                        className={` text-xs xl:text-sm leading-[1.5rem] lg:leading-[2rem] xl:leading-[2.5rem]`}
                                                    >
                                                        If you need to cancel your scheduled appointment,
                                                        please just let us know asap. We fully understand
                                                        appointments sometimes need to be rescheduled or
                                                        cancelled, and do not charge you if you cannot make
                                                        it. However please try to understand that if you
                                                        don't attend or let us know, it really hurts our
                                                        bussines, so we just ask that you try to let us know
                                                        asap.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Transition.Child>
                                </div>
                            </Dialog>
                        </Transition.Root>
                    </div>
                </div>
            </div>


            {/*gallery 7*/}
            <div className={`hidden overflow-hidden`}>
                <Slider className={`bg-dark`} {...settings}>
                    {gallery.map((data, i) => (
                        <div key={i} className={`relative group`}>
                            <img className={` w-full h-64`} src={data.image} alt=""/>
                        </div>
                    ))}
                </Slider>
            </div>


            <Footer/>
        </div>
    );
}
