import Navbar from "./Navbar";
import Footer from "./Footer";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../img/logo.svg";
import React from "react";

export default function Login() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const loginUser = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/login`, {
        email,
        password,
      })
      .then((response) => {
        localStorage.setItem("User", JSON.stringify(response.data));
        navigate("/admin/dashboard");
      })
      .catch((err) => {});
  };

  const handleChangeSignIn = (text) => (e) => {
    setFormData({ ...formData, [text]: e.target.value });
  };

  return (
    <div>
      <Navbar />

      {/*header*/}
      <div
        className={`relative -z-20 bg-black flex flex-col mx-auto items-center py-12 s:py-16 sm:py-20 xl:py-24 space-y-3 xl:space-y-5`}
      >
        <img
          className={`-z-10 opacity-20 absolute w-24 w-[150px] lg:w-[200px] xl:w-[300px] top-1/2 -translate-y-1/2`}
          src={logo}
          alt=""
        />

        <h1
          className={`z-0 uppercase text-white font-teko text-center text-5xl xl:text-6xl`}
        >
          login
        </h1>
      </div>

      {/*section 1*/}
      <div className={`bg-[#f4f2eb] relative`}>
        <div
          className={`2xl:container mx-auto py-20  sm:pb-36 lg:pt-24 lg:pb-48 xl:pt-28 xl:pb-60`}
        >
          <form
            onSubmit={loginUser}
            className={`mx-auto w-4/5 xs:w-3/5 sm:w-1/2 md:w-5/12 xl:w-3/12 flex flex-col items-center justify-center `}
          >
            <div className={`w-full  border border-darker`}>
              <input
                type="email"
                className={`w-full focus:outline-none bg-transparent py-2.5 xl:py-3 pl-4 text-[10px] xl:text-xs placeholder:text-[#787675] text-[#787675]`}
                placeholder={`EMAIL ADRESS`}
                onChange={handleChangeSignIn("email")}
                value={email}
                required
              />
            </div>
            <div className={`mt-4 s:mt-6 w-full  border border-darker`}>
              <input
                type="password"
                className={`w-full focus:outline-none bg-transparent py-2.5 xl:py-3 pl-4 text-[10px] xl:text-xs placeholder:text-[#787675] text-[#787675]`}
                placeholder={`PASSWORD`}
                onChange={handleChangeSignIn("password")}
                value={password}
                required
              />
            </div>

            <button
              type="submit"
              className={`uppercase mt-8 s:mt-12  mx-auto bg-primary text-white font-bold text-xs xl:text-sm px-10 xl:px-16 py-2.5 xl:py-3 rounded-3xl`}
            >
              login
            </button>
          </form>
        </div>
      </div>

      <Footer />
    </div>
  );
}
